import { DefaultSeo } from 'next-seo'

import { Admin, useDataProvider, useSession } from '@tootz/react-admin'

import {
  BRAND_NAME,
  BRAND_SHORT_NAME,
  RELEASE_YEAR,
  SITE_DESCRIPTION,
  SITE_URL,
  TWITTER_NAME
} from '../../../react-admin.config'
import useAbility from '../../services/ability'
import { api } from '../../services/api'

import getAsideMenuItems from './getAsideMenuItems'
import getNavbarMenuItems from './getNavbarMenuItems'
import getTopbarItems from './getTopbarItems'

const brand = {
  name: BRAND_NAME,
  shortName: BRAND_SHORT_NAME,
  siteUrl: SITE_URL
}

const logo = {
  default: '/logo-light.png',
  light: '/logo-light.png',
  dark: '/logo-dark.png',
  icon: {
    default: '/icon-blue.png',
    light: '/icon-blue.png',
    dark: '/icon-blue.png'
  }
}

const footer = {
  copyright: (
    <>
      <span className="text-muted fw-bold me-1">{RELEASE_YEAR} ©</span>
      <span className="text-gray-800">
        {BRAND_NAME}. Todos os direitos reservados.
      </span>
    </>
  ),
  menuItems: []
}

const MyAdmin = ({ children }) => {
  const { data: session } = useSession()
  const dataProvider = useDataProvider('', api)
  const fetcher = (resource, init) =>
    api.get(resource, init).then(res => res.data)
  const asideMenuItems = getAsideMenuItems(session.user)
  const navbarMenuItems = getNavbarMenuItems(session.user)
  const topbarItems = getTopbarItems(session.user)
  const ability = useAbility(session.user)

  return (
    <>
      <DefaultSeo
        titleTemplate={`${BRAND_NAME} | %s`}
        defaultTitle={BRAND_NAME}
        description={SITE_DESCRIPTION}
        openGraph={{
          type: 'website',
          url: SITE_URL,
          title: BRAND_NAME,
          description: SITE_DESCRIPTION,
          locale: 'pt_BR',
          site_name: BRAND_NAME
        }}
        facebook={{ appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID }}
        twitter={{
          handle: TWITTER_NAME,
          site: TWITTER_NAME,
          cardType: 'summary_large_image'
        }}
      />
      <Admin
        ability={ability}
        httpClient={api}
        dataProvider={dataProvider}
        fetcher={fetcher}
        session={session}
        logo={logo}
        brand={brand}
        header={{
          topbarItems: topbarItems,
          navbarMenuItems: navbarMenuItems
        }}
        aside={{ theme: 'dark', menuItems: asideMenuItems }}
        footer={footer}
      >
        {children}
      </Admin>
    </>
  )
}

export default MyAdmin
