import Link from 'next/link'

import getQuickUserMenuItems from './getQuickUserMenuItems'

const getTopbarItems = user => {
  const topbarItems = [
    // { slug: "search", config: null },
    // { slug: "activities", config: null },
    // { slug: "notifications", config: null },
    // { slug: "quick-links", config: null },
    {
      slug: 'quick-user',
      config: {
        hideName: true,
        menuItems: getQuickUserMenuItems(user)
      }
    }
  ]

  return topbarItems
}

export default getTopbarItems
