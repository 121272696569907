import { useWatch } from 'react-hook-form'

import {
  QuillTextEditor,
  FormGroup,
  FormRadio,
  FormControl,
  FormCheck,
  Select
} from '@/packages/@tootz/react-admin/form'

const NotifyModalFields = ({
  digitalProduct,
  withSchedule,
  withLink,
  withUser
}) => {
  const watchSelectUser = useWatch({ name: 'client_kind' })
  // const watchDeliveryAt = useWatch({ name: 'notify_now' })

  const user = withUser

  return (
    <>
      {!withUser && (
        <FormGroup
          control={FormRadio}
          name="client_kind"
          label="Notificar quais tipos de usuários?"
          solid
          inline
          required
          vertical
          options={[
            { label: 'Todos', value: 'all' },
            { label: 'Inadimplentes', value: 'overdue' },
            {
              label: 'Usuários específicos',
              value: 'choose'
            }
          ]}
        />
      )}

      {/* {withSchedule && (
        <>
          <FormGroup
            control={FormCheck}
            solid
            name="notify_now"
            label="Agendar notificação?"
            options={[{ label: 'Sim', value: true }]}
          />
          {watchDeliveryAt && (
            <FormGroup
              name="delivery_at"
              control={DateInput}
              label="Notificar em:"
              withTime
              solid
              vertical
              required
            />
          )}
        </>
      )} */}

      {/* ransacker owner_id isnt null owner_id_not_null:true */}

      {watchSelectUser == 'choose' && (
        <FormGroup
          label="Selecionar usuários"
          name="user_ids"
          control={Select}
          async={{
            href: '/admin/digital_products/drdream/clients',
            valueKey: 'owner_id'
          }}
          multiple
          solid
          vertical
        />
      )}
      <FormCheck name="email_delivery" defaultValue={true} hidden />
      <FormGroup
        name="title"
        label="Título da notificação"
        control={FormControl}
        solid
        required
        vertical
        helpText="O título deve ter no máximo 64 caracteres!"
      />

      <FormGroup
        name="content"
        label="Corpo da notificação"
        control={QuillTextEditor}
        placeholder="Mensagem super bacana! :)"
        as="textarea"
        className="min-h-150px"
        solid
        required
        vertical
        on
      />
      {/* {withLink && (
        <>
          <FormGroup
            control={FormControl}
            name="link_title"
            label="Título do Link"
            solid
            vertical
          />
          <FormGroup
            control={FormControl}
            name="link_url"
            label="Link"
            solid
            vertical
          />
        </>
      )} */}
    </>
  )
}

export default NotifyModalFields
