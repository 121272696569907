import { createContext, useContext, useState, useEffect } from 'react'

import { useRouter } from 'next/router'
import { getSession, signIn } from 'next-auth/react'

const AppContext = createContext()

const isDev = process.env.NODE_ENV == 'development'

const useAppContext = () => useContext(AppContext)

const AppProvider = ({ session, children, ...rest }) => {
  const router = useRouter()
  const [_showModal, _setShowModal] = useState(false)
  const showModal = _setShowModal

  // const checkSession = async callback => {
  //   const session = await getSession()

  //   if (!session) {
  //     showModal({
  //       component: AuthModal,
  //       onSignIn: callback
  //     })

  //     return false
  //   } else return true
  // }

  const Modal = ({ component, onHide, onSave, ...rest }) => {
    const Component = component

    return (
      <Component
        {...rest}
        show={_showModal}
        onHide={() => {
          onHide && onHide()
          _setShowModal(false)
        }}
        onSave={record => {
          onSave && onSave(record)
          _setShowModal(false)
        }}
      />
    )
  }

  useEffect(() => {
    const verifyClient = async () => {
      if (router.pathname.includes('/auth')) return
      const session = await getSession()

      if (session) {
        const { user } = session
        const hasAdminRole = _.get(user, 'roles', []).find(role => !role.client)

        if (!hasAdminRole) router.push(process.env.NEXT_PUBLIC_WEBAPP_BASE_URL)
      }
    }

    verifyClient()
  }, [])

  return (
    <AppContext.Provider
      value={{
        showModal,
        // checkSession,
        ...rest
      }}
    >
      {children}
      {_showModal && <Modal {..._showModal} />}
    </AppContext.Provider>
  )
}

// eslint-disable-next-line react/display-name
const withApp = Component => props => {
  return (
    <AppProvider>
      <Component {...props} />
    </AppProvider>
  )
}

export { useAppContext, AppProvider, withApp }
