import _ from 'lodash'

import { defineAbility } from '@casl/ability'

import { DigitalProduct } from './entities'
import { User } from './entities'

const useAbility = user =>
  defineAbility(can => {
    if (!user) return

    const roles = _.get(user, 'roles', [user.main_role])

    roles.forEach(role => {
      const roleName = _.get(role, 'permission_names.0')

      if (roleName == ':') {
        can('manage', 'all')
      }

      if (roleName == 'admin/productor:') {
        can('manage', 'DigitalProduct', { responsible_id: user.id })
      }
    })
  })

export default useAbility
export { User, DigitalProduct }
