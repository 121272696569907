import _ from 'lodash'

export const isAdmin = user => _.get(user, 'roles.0.permission_names') == ':'

export const isProductor = user =>
  _.get(user, 'roles.0.names')?.includes('Produtor')

export const isSeller = user =>
  _.get(user, 'roles.0.names')?.includes('Vendedor')

export const isFinancial = user =>
  _.get(user, 'roles.0.names')?.includes('Financeiro')
