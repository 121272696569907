import { useEffect } from 'react'

import { FormProvider, useForm, useWatch } from 'react-hook-form'
import toastr from 'toastr'

import { Modal } from '@/packages/@tootz/react-admin/components'
import { api } from '@/services/api'

import NotifyModalFields from './Fields'

const NotifyModal = ({
  onHide,
  show,
  digitalProduct,
  withLink,
  withSchedule,
  withUser
}) => {
  const methods = useForm()
  const user = withUser

  const onSubmit = data => {
    if (withUser) {
      api
        .post('/admin/notification_messages', {
          record: {
            email_delivery: true,
            user_ids: [user.owner_id],
            client_kind: 'choose',
            ...data
          }
        })
        .then(_ => toastr.success('Usuários notificados com sucesso!'))
    } else {
      api
        .post('/admin/notification_messages', {
          record: {
            email_delivery: true,
            user_ids: [],
            ...data
          }
        })
        .then(_ => toastr.success('Usuários notificados com sucesso!'))
    }

    onHide && onHide()
  }
  return (
    <Modal show={show} size="lg">
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <Modal.Header onHide={onHide}>
            <Modal.Icon className="fa-solid fa-flag text-primary" />
            <Modal.Title>
              {withUser
                ? 'Notificar usuário'
                : `Notificar usuários do ${digitalProduct.name}`}
            </Modal.Title>
            <Modal.Subtitle>
              Escolha agora uma mensagem super legal pra enviar ao
              {!withUser && 's'} aluno{!withUser && 's'}!
            </Modal.Subtitle>
          </Modal.Header>
          <Modal.Body>
            <NotifyModalFields
              digitalProduct={digitalProduct}
              withSchedule={withSchedule}
              withLink={withLink}
              withUser={withUser}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-md"
            >
              Cancelar
            </button>
            <button type="submit" className="btn btn-primary btn-md">
              Notificar
            </button>
          </Modal.Footer>
        </FormProvider>
      </form>
    </Modal>
  )
}

export default NotifyModal
