import { useState } from 'react'
import { createContext, useContext } from 'react'

import { toast } from 'react-toastify'

import NotifyModal from '../Modal'

import { useAppContext } from '@/domain/App/context'

const NotifyContext = createContext(null)

const useNotifyContext = () => useContext(NotifyContext)

const NotifyContent = ({ digitalProduct, onCreateNotification }) => {
  return (
    <>
      <div className="d-flex  justify-content-center align-items-center gap-1">
        <span className="text-justify d-block fs-7">
          Você deseja notificar alunos que possuem o produto{' '}
          {digitalProduct?.name} que houveram mudanças?
        </span>
        <div className="d-flex gap-2">
          <button className="btn btn-light btn-sm text-nowrap">Não</button>

          <button
            className="btn btn-primary btn-sm text-nowrap"
            onClick={onCreateNotification}
          >
            Sim
          </button>
        </div>
      </div>
    </>
  )
}

const NotificationsProvider = ({ children }) => {
  const { showModal } = useAppContext()

  const handleNotificationModal = (digitalProduct, user) => {
    showModal({
      component: NotifyModal,
      digitalProduct: digitalProduct,
      withUser: user
      // withSchedule: withSchedule,
      // withLink: withLink
    })
  }

  const triggerNotificationToast = (digitalProduct, user) => {
    toast(
      <NotifyContent
        digitalProduct={digitalProduct}
        withUser={user}
        onCreateNotification={() => handleNotificationModal(digitalProduct)}
      />,
      {
        position: 'bottom-center',
        autoClose: false,
        hideProgressBar: false,
        style: { width: 540, padding: 16, margin: 0 },
        icon: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        width: '1000px',
        theme: 'light',
        containerId: 'test'
      }
    )
  }

  return (
    <NotifyContext.Provider
      value={{
        triggerNotificationToast,
        handleNotificationModal
      }}
    >
      {children}
    </NotifyContext.Provider>
  )
}

export { useNotifyContext, NotificationsProvider }
