import _ from 'lodash'

import { isAdmin, isProductor, isSeller, isFinancial } from '@/utils/userIs'

const getAsideMenuItems = user => {
  return [
    {
      slug: 'management',
      title: 'Gestão',
      isSection: true
    },
    {
      slug: 'digital-products',
      title: 'Produtos Digitais',
      icon: 'fad fa-box-open',
      href: '/digital-products',
      condition: isAdmin(user) || isProductor(user)
    },
    {
      slug: 'products',
      title: 'Produtos',
      icon: 'fad fa-shopping-bag',
      condition: isAdmin(user) || isSeller(user),
      items: [
        {
          slug: 'products',
          title: 'Listar',
          href: '/products',
          condition: isAdmin(user) || isSeller(user)
        },
        {
          slug: 'product_revisions',
          title: 'Para Revisão',
          href: '/product-revisions',
          condition: isAdmin(user)
        }
      ]
    },
    {
      slug: 'contracts',
      title: 'Contratos',
      icon: 'fad fa-file-signature',
      condition: isAdmin(user) || isSeller(user) || isFinancial(user),
      items: [
        {
          slug: 'contracts-list',
          title: 'Geral',
          href: '/contracts',
          condition: isAdmin(user) || isSeller(user) || isFinancial(user)
        },
        {
          slug: 'contracts-pending',
          title: 'Pendentes de Aprovação',
          href: '/contracts/pending',
          condition: isAdmin(user) || isFinancial(user)
        }
      ]
    },
    {
      slug: 'orders',
      title: 'Cobranças',
      icon: 'fad fa-money-bill-transfer',
      condition: isAdmin(user) || isSeller(user) || isFinancial(user),
      items: [
        {
          slug: 'orders',
          title: 'Geral',
          href: '/orders/all',
          condition: isAdmin(user) || isSeller(user)
        },
        {
          slug: 'order_revisions',
          title: 'Para Revisão',
          href: '/order-revisions',
          condition: isAdmin(user)
        },
        {
          slug: 'order_pending_proofs',
          title: 'Comprovantes Pendentes',
          href: '/orders/pending-proofs',
          condition: isAdmin(user) || isFinancial(user)
        }
      ]
    },
    {
      slug: 'purchase-attempts',
      title: 'Tentativas de compra',
      icon: 'fad fa-coins',
      href: '/purchase-attempts',
      condition: isAdmin(user)
    },
    {
      slug: 'coupons',
      title: 'Cupons de Desconto',
      icon: 'fad fa-badge-percent',
      href: '/coupons',
      condition: isAdmin(user)
    },
    {
      slug: 'company',
      title: 'Administração',
      isSection: true,
      condition: isAdmin(user)
    },
    {
      slug: 'users',
      title: 'Usuários',
      icon: 'fad fa-user-group-crown',
      href: '/users',
      condition: isAdmin(user)
    },
    {
      slug: 'file-requests-group',
      title: 'Documentos',
      icon: 'fad fa-files',
      items: [
        {
          slug: 'file-request-templates',
          href: '/file-request-templates',
          title: 'Templates'
        },
        {
          slug: 'file-requests',
          href: '/file-requests',
          title: 'Solicitações'
        }
      ],
      condition: isAdmin(user)
    },
    {
      slug: 'reports',
      title: 'Reports',
      icon: 'fad fa-user-doctor-message',
      href: '/reports',
      condition: isAdmin(user)
    },
    {
      slug: 'notifications',
      title: 'Notificações',
      icon: 'fad fa-bell',
      href: '/notifications',
      condition: isAdmin(user)
    }
  ]
}

export default getAsideMenuItems
