import { useEffect } from 'react'

import TimeAgo from 'javascript-time-ago'
import pt from 'javascript-time-ago/locale/pt'
import _ from 'lodash'
import { Settings } from 'luxon'
import { SessionProvider } from 'next-auth/react'
import { DefaultSeo } from 'next-seo'
import TagManager from 'react-gtm-module'
import { ToastContainer } from 'react-toastify'

import {
  Hydrate,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'

import {
  RELEASE_YEAR,
  BRAND_NAME,
  BRAND_SHORT_NAME,
  SITE_URL,
  SITE_DESCRIPTION,
  TWITTER_NAME
} from '../../react-admin.config'

import { AppProvider } from '@/domain/App/context'
import AuthGuard from '@/domain/AuthGuard'
import { NotificationsProvider } from '@/domain/Notifications/context/NotificationsContext'
import { api } from '@/services/api'

import '../../styles/main.scss'
import 'react-toastify/dist/ReactToastify.css'

Settings.defaultZone = 'America/Sao_Paulo'
Settings.defaultLocale = 'pt-BR'

const defaultQueryFn = async ({ queryKey }) => {
  const { data } = await api.get(queryKey[0])
  return data
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      refetchInterval: 0,
      refetchIntervalInBackground: 60000,
      retry: true,
      refetchOnMount: true,
      retryDelay: attempt =>
        Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
      queryFn: defaultQueryFn
    }
  }
})

const App = ({ Component, pageProps: { session, ...pageProps } }) => {
  useEffect(() => {
    // Google Tag Manager
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID })

    // React Time Ago
    TimeAgo.setDefaultLocale(pt)
    TimeAgo.addLocale(pt)

    // Resize Observer Polyfill
    if (typeof window !== 'undefined' && !window.ResizeObserver) install()
  }, [])

  useEffect(() => {
    const body = document.querySelector('body')

    document.documentElement.setAttribute('data-bs-theme', 'light')

    if (body) {
      body.id = 'ttz_app_body'
      body.setAttribute('data-ttz-app-layout', 'dark-sidebar')
      body.setAttribute('data-ttz-app-header-fixed', 'true')
      body.setAttribute('data-ttz-app-sidebar-enabled', 'true')
      body.setAttribute('data-ttz-app-sidebar-fixed', 'true')
      body.setAttribute('data-ttz-app-sidebar-hoverable', 'true')
      body.setAttribute('data-ttz-app-sidebar-push-header', 'true')
      body.setAttribute('data-ttz-app-sidebar-push-toolbar', 'true')
      body.setAttribute('data-ttz-app-sidebar-push-footer', 'true')
      body.setAttribute('data-ttz-app-toolbar-enabled', 'true')
    }
  })

  return (
    <>
      <DefaultSeo
        titleTemplate={`%s - ${BRAND_NAME}`}
        defaultTitle={BRAND_NAME}
        description={SITE_DESCRIPTION}
        lang="pt_BR"
        openGraph={{
          type: 'website',
          url: SITE_URL,
          title: BRAND_NAME,
          description: SITE_DESCRIPTION,
          locale: 'pt_BR',
          site_name: BRAND_NAME
        }}
        facebook={{ appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID }}
        twitter={{
          handle: TWITTER_NAME,
          site: TWITTER_NAME,
          cardType: 'summary_large_image'
        }}
        additionalLinkTags={[
          {
            rel: 'apple-touch-icon',
            sizes: '180x180',
            href: '/apple-touch-icon.png'
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '32x32',
            href: '/favicon-32x32.png'
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '16x16',
            href: '/favicon-16x16.png'
          },
          { rel: 'manifest', href: '/site.webmanifest' }
        ]}
        additionalMetaTags={[
          {
            name: 'msapplication-TileColor',
            content: '#ffffff'
          },
          {
            name: 'theme-color',
            content: '#ffffff'
          }
        ]}
      />
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <SessionProvider
            staleTime={5 * 60}
            clientMaxAge={5 * 60}
            refetchInterval={0}
            session={session}
          >
            <AuthGuard isPublic={Component.isPublic}>
              <AppProvider>
                <NotificationsProvider>
                  <Component {...pageProps} />
                </NotificationsProvider>
              </AppProvider>
            </AuthGuard>
          </SessionProvider>
        </Hydrate>
      </QueryClientProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        closeButton={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        limit={1}
      />
    </>
  )
}

export default App
